import React from 'react';
import { routes } from './AppRoutes';

class App extends React.Component {
  render() {
    return (
      <>
        {routes}
      </>
    );
  }
}

export default App;
