import { useSelector } from 'react-redux';
import styles from '../styles/CTI.module.scss';
import { AGENT_PENDING_BUSY_STATE, AGENT_BUSY_STATE, AGENT_INCOMING_STATE, AGENT_ON_CONTACT_STATE } from '../constants/agentConstants';

const AgentInfo = ({ queueName, incomingCallFlag, transfer, quickConnect }) => {
  const agent = useSelector(state => state.agent);
  const { agentName, agentState } = agent;

  return (
    <div className={styles.agentinfo__container}>
      <div><span className={styles.agentinfo__label}>Agent Name:</span> {agentName}</div>
      <div><span className={styles.agentinfo__label}>Agent State:</span> {agentState === AGENT_BUSY_STATE ? AGENT_ON_CONTACT_STATE : agentState === AGENT_PENDING_BUSY_STATE ? AGENT_INCOMING_STATE : agentState}</div>
      {incomingCallFlag ? <div><span className={styles.agentinfo__label}>Queue Name:</span> {queueName}</div> : null }
      {transfer && incomingCallFlag ? <div><span className={styles.agentinfo__label}>Initiation Method: </span> Transfer</div> : null }
      {quickConnect && incomingCallFlag ? <div><span className={styles.agentinfo__label}>Quick Connect: </span> {quickConnect}</div> : null }
    </div>
  );
};

export default AgentInfo;
