import {SET_CUST_INFO} from '../constants/customerConstants';

export const customerReducer = (state = {
  custInfo: {
    brandAttribute: '',
    cardNumber:'',
    ivrDropoutCode:'',
    lastTransaction:'',
    identificationStatus:false,
    verificationStatus:false,
    verificationQuestion:'',
    isUpdated:false
  }}, action) => {

  switch (action.type) {
  case SET_CUST_INFO:
    return { ...state, custInfo: action.payload };

  default:
    return state;
  }
};
