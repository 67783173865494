import {
  SET_CONTACT_ATTRIBUTES,
  SET_INCOMING_CALL_FLAG,
  SET_QUEUE_INFO,
  SET_QUICK_CONNECT,
  SET_TRANSFER_FLAG,
  SET_BRAND_ATTRIBUTES,
  INBOUND_CALL_TYPE
} from '../constants/contactConstants';

export const contactReducer = (state = { contactAttributes: {aid:'',sak:'',sst:'',ContactID:'',InitialContactID:'',PreviousContactID:''}, incomingCallFlag: false, queueInfo: {}, transfer: false, quickConnect: '',brandAttributes:'',isInbound:false }, action) => {
  switch (action.type) {
  case SET_CONTACT_ATTRIBUTES:
    return { ...state, contactAttributes: action.payload };

  case SET_INCOMING_CALL_FLAG:
    return { ...state, incomingCallFlag: action.payload };

  case SET_QUEUE_INFO:
    return { ...state, queueInfo: action.payload };

  case SET_TRANSFER_FLAG:
    return { ...state, transfer: action.payload };

  case SET_QUICK_CONNECT:
    return { ...state, quickConnect: action.payload };

  case SET_BRAND_ATTRIBUTES:
    return {...state, brandAttributes: action.payload};

  case INBOUND_CALL_TYPE:
    return {...state, isInbound:action.payload};

  default:
    return state;
  }
};
