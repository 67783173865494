import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { setIncomingCallFlag, setQueueInfo, setQuickConnect, setTransferFlag,setContactAttributes,setIsInbound } from '../actions/contactActions';
import {setCustInfo} from '../actions/customerActions';
import { DEV, SIT, UAT, PPE, PROD } from '../constants/envConstants';
import { connect } from '../shared/utility';

const getScreenPopData = async (requestUrl, body, headers) => {
  try {
    const result = await axios.post(requestUrl, body, headers);
    const contactId = result?.data?.contactId || '';
    const customerNumber = result?.data?.customerNumber || '';
    const sortCodeAccountNumber = result?.data?.sortCodeAccountNumber || '';
    const idvLevel = result?.data?.idvLevel || '';
    window.open(`AWSListener:${contactId}~${customerNumber}~${sortCodeAccountNumber}~${idvLevel}~~`, '_self');
    if (process.env.REACT_APP_ENV === DEV || process.env.REACT_APP_ENV === SIT || process.env.REACT_APP_ENV === UAT) {
      console.log(`External protocol - AWSListener:${contactId}~${customerNumber}~${sortCodeAccountNumber}~${idvLevel}~~`);
    }
    if (process.env.REACT_APP_ENV === PPE || process.env.REACT_APP_ENV === PROD) {
      console.log('External protocol - AWSListener:####~####~####~####~####~');
    }
  } catch (err) {
    const o = new connect.Agent().getContacts()[0];
    window.open(`AWSListener:${o.getContactId()}~~~~~`, '_self');
    if (process.env.REACT_APP_ENV === DEV || process.env.REACT_APP_ENV === SIT || process.env.REACT_APP_ENV === UAT) {
      console.log(`External protocol - AWSListener:${o.getContactId()}~~~~~`);
    }
    if (process.env.REACT_APP_ENV === PPE || process.env.REACT_APP_ENV === PROD) {
      console.log('External protocol - AWSListener:####~####~####~####~####~');
    }
  }
};

const getAFSCustomerData=async (requestUrl, body, headers) => {
  try{
    const result = await axios.post(requestUrl, body, headers);
    console.log(requestUrl,'cust response: ',result);
    const custInfoObj={
      cardNumber: result.data?.cardNumber || '',
      ivrDropoutCode: result.data?.lastTransaction?.name || '',
      lastTransaction: result.data?.lastTransaction?.status || false,
      identificationStatus: !isNaN(result.data?.cardNumber),
      verificationStatus: result.data?.isVerified || false,
      verificationQuestion:'Date of Birth',
      brandAttribute:'AFS'
    };
    return custInfoObj;
  }catch{
    const afsCustomerData = {
      cardNumber:'',
      brandAttribute:'AFS',
      ivrDropoutCode:'',
      lastTransaction:'',
      identificationStatus:false,
      verificationStatus:false,
      verificationQuestion:'',
      isUpdated:false
    };
    return afsCustomerData;
  }
};

const signedRequestUrl= async(body,domain, region, accessKey, secretKey, sessionToken,brandAttribute)=>{
  try{
    const signRequest = new HttpRequest({
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'host': process.env.REACT_APP_AL_API_HOST,
      },
      hostname: domain,
      method: 'POST',
      path: (brandAttribute==='AFS')?'/awsconnectal/v1/custinfo':'/awsconnectal/v1/awslistener',
    });
    const signer = new SignatureV4({
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey: secretKey,
        sessionToken: sessionToken,
      },
      region: region,
      service: 'execute-api',
      sha256: Sha256,
    });
    const signedRequest = await signer.sign(signRequest);
    const requestUrl= signedRequest.hostname + signRequest.path;
    return {requestUrl: requestUrl,body:signRequest.body,headers:signedRequest.headers};
  }catch(err){
    console.log('Error in signedRequestUrl: ',err);
    return err;
  }
};

const callApi = async (body, domain, region, accessKey, secretKey, sessionToken) => {
  try {
    const signedObj = await signedRequestUrl(body,domain, region, accessKey, secretKey, sessionToken,'SB');
    await getScreenPopData(signedObj.requestUrl, signedObj.body, { headers: signedObj.headers });
  }catch {
    const o = new connect.Agent().getContacts()[0];
    window.open(`AWSListener:${o.getContactId()}~~~~~`, '_self');
  }
};

export const useContact = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    connect.contact((contact) => {
      contact.onRefresh(() => {
        console.log('Refresh');
        const o = new connect.Agent().getContacts();
        if (o?.length) {
          const thirdConnection = o[0].getThirdPartyConnections();
          if (thirdConnection.length && thirdConnection[0].isActive()) {
            const quickConnect = thirdConnection[0].getQuickConnectName();
            dispatch(setQuickConnect(quickConnect));
          } else {
            dispatch(setQuickConnect(''));
          }
        }
      });
      contact.onIncoming(() => console.log('Incoming'));
      contact.onAccepted(() => console.log('Accepted'));
      contact.onConnecting(() => {
        setTimeout(() => {
          (async () => {
            const contactAttributes = contact.getAttributes();
            console.log('[React] setTimeout Contact Attributes', contactAttributes);
            if(contactAttributes.Brand_Id === undefined || contactAttributes.Brand_Id?.value !== 'AFS'){
              const body = {
                'Details': {
                  'Parameters': {
                    'contactId': contactAttributes.ContactID?.value,
                    'initialContactId': contactAttributes.InitialContactID?.value,
                    'previousContactId': contactAttributes.PreviousContactID?.value,
                    'brandAttribute':'SB'
                  },
                },
              };
              await callApi(body, process.env.REACT_APP_AL_API_URL, process.env.REACT_APP_CCP_REGION, contactAttributes.aid?.value, contactAttributes.sak?.value, contactAttributes.sst?.value);
            }
            else{
              const body = {
                'Details': {
                  'Parameters': {
                    'contactId': contactAttributes.ContactID?.value,
                    'initialContactId': contactAttributes.InitialContactID?.value,
                    'previousContactId': contactAttributes.PreviousContactID?.value,
                    'brandAttribute':contactAttributes.Brand_Id?.value,
                    'action':'custInfo'
                  },
                },
              };
              const setContactState={
                'aid':contactAttributes.aid?.value,
                'sak':contactAttributes.sak?.value,
                'sst':contactAttributes.sst?.value,
                'ContactID':contactAttributes.ContactID?.value || '',
                'InitialContactID':contactAttributes.InitialContactID?.value || '',
                'PreviousContactID':contactAttributes.PreviousContactID?.value || ''
              };
              dispatch(setContactAttributes(setContactState));
              const signedObj= await signedRequestUrl(body,process.env.REACT_APP_AL_API_URL, process.env.REACT_APP_CCP_REGION, contactAttributes.aid?.value, contactAttributes.sak?.value, contactAttributes.sst?.value,'AFS');
              const custInfoObj= await getAFSCustomerData(signedObj.requestUrl, signedObj.body, { headers: signedObj.headers });
              dispatch(setCustInfo(custInfoObj));
            }
          })();
        }, 4000);
      });
      contact.onConnecting(() => {
        console.log('Connecting');
        const o = new connect.Agent().getContacts()[0];
        if (o.getContactId() !== o.getInitialContactId() && contact.isInbound()) {
          dispatch(setTransferFlag(true));
        }
        dispatch(setQueueInfo(contact.getQueue()));
        dispatch(setIncomingCallFlag(true));
        dispatch(setIsInbound(contact.isInbound()));
      });
      contact.onPending(() => console.log('On Pending'));
      contact.onEnded(() => {
        console.log('Ended');
        dispatch(setTransferFlag(false));
        dispatch(setQuickConnect(''));
        dispatch(setIncomingCallFlag(false));
        dispatch(setIsInbound(false));
        dispatch(setCustInfo({ brandAttribute: '',cardNumber:'',ivrDropoutCode:'', lastTransaction:'',identificationStatus:false,verificationStatus:false,verificationQuestion:'',isUpdated:false}));
        dispatch(setContactAttributes({ aid:'',sak:'',sst:'',ContactID:'',InitialContactID:'',PreviousContactID:''}));
      });
    });
  }, []);
};