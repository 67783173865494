import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAgentName, setAgentState } from '../actions/agentActions';
import { connect } from '../shared/utility';

export const useAgent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    connect.agent((agent) => {
      agent.onRefresh(() => {
        console.log('Refresh');
        dispatch(setAgentName(agent.getName()));
        dispatch(setAgentState(agent.getStatus().name));
      });
      agent.onRoutable(() => console.log('Routable'));
      agent.onNotRoutable(() => console.log('Not Routable'));
      agent.onOffline(() => console.log('Offline'));
    });
  }, []);
};
