import { SET_AGENT_NAME, SET_AGENT_STATE } from '../constants/agentConstants';

export const setAgentName = (payload) => {
  return dispatch => {
    dispatch({ type: SET_AGENT_NAME, payload });
  };
};

export const setAgentState = (payload) => {
  return dispatch => {
    dispatch({ type: SET_AGENT_STATE, payload });
  };
};
