import { useEffect, useState } from 'react';
import { connect } from '../shared/utility';
import { OFFLINE_STATE, AGENT_LOGGED_IN_STATE } from '../constants/agentConstants';

export const useConnect = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initializeConnect();

    connect.core.onInitialized(() => {
      setInitialized(true);
      connect.agent((agent) => {
        const currentState = agent.getState();
        if (currentState.name == OFFLINE_STATE && sessionStorage.getItem('reloaded') !== null) {
          const state = agent.getAgentStates().find(item => item.name === AGENT_LOGGED_IN_STATE);
          agent.setState(state);
        }
      });
    });

    sessionStorage.setItem('reloaded', 'true');
    const eventBus = connect.core.getEventBus();
    eventBus.subscribe(connect.EventType.TERMINATED, () => {
      console.log('logged out');
      // Forcing reload due to a bug introduced in streams v1.6.10
      document.location.reload();
    });

    return () => {
      terminateConnect();
    };
  }, []);

  return initialized;
};

const initializeConnect = () => {
  console.log('init');
  const containerDiv = document.getElementById('ccp');
  connect.core.initCCP(containerDiv, {
    ccpUrl: process.env.REACT_APP_CCP_URL,
    loginPopup: true,
    loginPopupAutoClose: true,
    loginUrl: process.env.REACT_APP_LOGIN_URL,
    region: process.env.REACT_APP_CCP_REGION,
    softphone: {
      allowFramedSoftphone: true,
      disableRingtone: false,
    },
    pageOptions: {
      enableAudioDeviceSettings: true,
      enablePhoneTypeSettings: false
    }
  });
};

const terminateConnect = () => {
  console.log('terminate');
  connect.core.terminate();
  const containerDiv = document.getElementById('ccp');

  while (containerDiv?.firstChild) {
    containerDiv.firstChild.remove();
  }
};
