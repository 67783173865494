import Popup from 'reactjs-popup';
import { BiSolidEdit } from 'react-icons/bi';
import { FiAlertTriangle } from 'react-icons/fi';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HttpRequest } from '@aws-sdk/protocol-http';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import styles from '../styles/CTI.module.scss';
import 'reactjs-popup/dist/index.css';
import { setCustInfo } from '../actions/customerActions';
import { Sha256 } from '@aws-crypto/sha256-browser';
import axios from 'axios';

const CardUpdateModal = ({isOpenPopupFlag,errorObjProps,initialCardState}) => {
  const [popupData,setPopupData]=useState(initialCardState);
  const [errorObj,setErrorObj]=useState(errorObjProps);
  const [isOpenPopup,setIsOpenPopup]=useState(isOpenPopupFlag);
  const contactState = useSelector(state => state.contact);
  const { contactAttributes }=contactState;

  const dispatch = useDispatch();
  const customerState = useSelector(state=>state.customer);

  const updateCardCustomerData = async (requestUrl, body, headers) => {
    try{
      await axios.post(requestUrl, body, headers);
      const custInfoObj={
        cardNumber: popupData?.updateCardNumber || '',
        brandAttribute:'AFS',
        ivrDropoutCode: customerState.custInfo?.ivrDropoutCode || '',
        lastTransaction: customerState.custInfo?.lastTransaction || false,
        identificationStatus: !isNaN(popupData?.updateCardNumber),
        verificationStatus: popupData?.verificationStatus || false,
        verificationQuestion: customerState.custInfo?.verificationQuestion || '',
        isUpdated:true
      };
      return custInfoObj;
    }catch{
      const custInfoObj={
        cardNumber: customerState.custInfo?.cardNumber || '',
        brandAttribute:'AFS',
        ivrDropoutCode: customerState.custInfo?.ivrDropoutCode || '',
        lastTransaction: customerState.custInfo?.lastTransaction || false,
        identificationStatus: customerState.custInfo?.identificationStatus,
        verificationStatus: customerState.custInfo?.verificationStatus || false,
        verificationQuestion: customerState.custInfo?.verificationQuestion || '',
        isUpdated:false
      };
      return custInfoObj;
    }
  };

  const cardUpdateApi = async (body, domain, region, accessKey, secretKey, sessionToken) => {
    try {
      const signRequest = new HttpRequest({
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          'host': process.env.REACT_APP_AL_API_HOST,
        },
        hostname: domain,
        method: 'POST',
        path: '/awsconnectal/v1/custinfo',
      });
      const signer = new SignatureV4({
        credentials: {
          accessKeyId: accessKey,
          secretAccessKey: secretKey,
          sessionToken: sessionToken,
        },
        region: region,
        service: 'execute-api',
        sha256: Sha256,
      });
      const signedRequest = await signer.sign(signRequest);
      const requestUrl= signedRequest.hostname + signRequest.path;
      return {requestUrl: requestUrl,body:signRequest.body,headers:signedRequest.headers};
    }catch(err) {
      console.log(`cardUpdateApi is failed ${err} `);
    }
  };

  const updateCard = async()=>{
    if(popupData?.updateCardNumber === ''){
      setErrorObj({...errorObj,status:true,msg:'Card Number Required !!'});
    }
    else{
      setIsOpenPopup(true);
      const body = {
        'Details': {
          'Parameters': {
            'contactId': contactAttributes?.ContactID,
            'initialContactId': contactAttributes?.InitialContactID,
            'previousContactId': contactAttributes?.PreviousContactID,
            'brandAttribute': 'AFS',
            'cardNumber': popupData?.updateCardNumber,
            'isVerified': popupData?.verificationStatus,
            'action':'idv'
          },
        },
      };
      const signedObj=await cardUpdateApi(body, process.env.REACT_APP_AL_API_URL, process.env.REACT_APP_CCP_REGION, contactAttributes?.aid,contactAttributes?.sak,contactAttributes?.sst);
      const custInfoObj=await updateCardCustomerData(signedObj.requestUrl, signedObj.body, { headers: signedObj.headers });

      if(custInfoObj?.isUpdated === false){
        setErrorObj({...errorObj,status:true,msg:'Card number is invalid !!'});
      }
      else{
        dispatch(setCustInfo(custInfoObj));
        setPopupData({ ...popupData,updateCardNumber:'',verificationStatus:true });
        setErrorObj({...errorObj,status:false,msg:''});
        setIsOpenPopup(false);
      }
    }
  };

  const popupHandleChange = event=>{
    if(errorObj.status === true){
      setErrorObj({...errorObj,status:false,msg:''});
    }
    if(event.target.name === 'verificationStatus'){
      setPopupData({...popupData, [event.target.name]: Boolean(event.target.value)});
    }
    else{
      setPopupData({...popupData, [event.target.name]: event.target.value});
    }
  };

  return (
    <div data-testid='popup'>
      <Popup open={isOpenPopup} contentStyle={{width: '300px'}} trigger={<span className={styles.customerinfo_edit}><BiSolidEdit title='edit-icon'/></span>} position='left center' modal>
        { close => (
          <div className='modal'>
            <div className='content'>
              <div>
                <span className={styles.customerinfo_modal_error}>
                  {errorObj.status?<span><FiAlertTriangle title='error-icon' data-testid='error-span'/> {errorObj.msg}</span>:''}
                </span>
                <span className={styles.customerinfo_modal_close} data-testid='closeIcon' onClick={() => close()}>&times;</span>
              </div>
              <div style={{padding:'4%'}} className='card-class'>
                <span className={styles.agentinfo__label} data-testid='card-number'>CardNumber</span>
                <input type='text' data-testid='input-id'  onChange={popupHandleChange} name='updateCardNumber' value={popupData.cardNumber}/>
              </div>
              <div className='verification-class'>
                <span className={styles.agentinfo__label} data-testid='verification-status'>VerificationStatus</span>
                <select name='verificationStatus' onChange={popupHandleChange}  className={styles.customerinfo_modal_varification}><option value='yes'>Yes</option><option value=''>No</option></select>
              </div>
              <div style={{align:'center'}}>
                <button className={styles.customerinfo_modal_button} data-testid='update-btn' onClick={updateCard}>Update</button>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default CardUpdateModal;