import styles from '../styles/RetryButton.module.scss';

const RetryButton = () => {
  return (
    <button
      className={styles.retry__button}
      onClick={() => document.location.reload()}
    >
      Retry
    </button>
  );
};

export default RetryButton;
