import {
  SET_AGENT_NAME,
  SET_AGENT_STATE,
} from '../constants/agentConstants';

export const agentReducer = (state = { agentName: '', agentState: '' }, action) => {
  switch (action.type) {
  case SET_AGENT_STATE:
    return { ...state, agentState: action.payload };
  case SET_AGENT_NAME:
    return { ...state, agentName: action.payload };

  default:
    return state;
  }
};
