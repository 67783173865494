import { VscClose } from 'react-icons/vsc';
import { FcCheckmark } from 'react-icons/fc';
import styles from '../styles/CTI.module.scss';
import CardUpdateModal from './CardUpdateModal';

const CustomerInfo = ({ cardNumber,brandAttribute,transferType,ivrDropoutCode,lastTransaction,identificationStatus,verificationStatus,verificationQuestion }) => {

  return (
    <div className={styles.agentinfo__container}>
      <CardUpdateModal isOpenPopupFlag={false} errorObjProps={{status:false, msg:''}} initialCardState={{updateCardNumber:'',verificationStatus:true}}/>
      <div><span className={styles.agentinfo__label}>Card Number:</span> {cardNumber}</div>
      <div><span className={styles.agentinfo__label}>Brand:</span> {brandAttribute}</div>
      <div><span className={styles.agentinfo__label}>Transfer Type:</span> {transferType}</div>
      <div><span className={styles.agentinfo__label}>IVR Dropout Code:</span> {ivrDropoutCode}</div>
      <div><span className={styles.agentinfo__label}>Last Transaction:</span> {lastTransaction}</div>
      <div><span className={styles.agentinfo__label}>Identification Status:</span><span>{identificationStatus?<FcCheckmark/>:<VscClose style={{color:'red'}}/>} </span></div>
      <div><span className={styles.agentinfo__label}>Verification Status:</span><span> {verificationStatus?<FcCheckmark/>:<VscClose style={{color:'red'}}/>}</span></div>
      <div><span className={styles.agentinfo__label}>Verification Question:</span> {verificationQuestion}</div>
    </div>
  );
};

export default CustomerInfo;