import React from 'react';
import 'amazon-connect-streams';
import { useConnect } from '../hooks/useConnect';
import SideBar from './SideBar';
import RetryButton from './Retry';
import Loader from './Loader';
import styles from '../styles/Main.module.scss';
import { useAgent } from '../hooks/useAgent';
import { useContact } from '../hooks/useContact';

const Main = () => {
  const isAuthenticated = useConnect();
  useAgent();
  useContact();

  return (
    <>
      {!isAuthenticated && (
        <Loader type={'lines'} text="Waiting for login">
          <RetryButton />
        </Loader>
      )}

      <div className={styles.container}>
        <div id="ccp" className={`${!isAuthenticated ? 'hide' : styles.ccp}`}></div>
        {isAuthenticated && <SideBar className={styles.sidebar}></SideBar>}
      </div>
    </>
  );
};

export default Main;
