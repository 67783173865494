import { SET_CONTACT_ATTRIBUTES, SET_INCOMING_CALL_FLAG, SET_QUEUE_INFO, SET_QUICK_CONNECT, SET_TRANSFER_FLAG,SET_BRAND_ATTRIBUTES,INBOUND_CALL_TYPE } from '../constants/contactConstants';

export const setContactAttributes = (payload) => {
  return dispatch => {
    dispatch({ type: SET_CONTACT_ATTRIBUTES, payload });
  };
};
export const setBrandAttributes = (payload) => {
  return dispatch => {
    dispatch({ type: SET_BRAND_ATTRIBUTES, payload });
  };
};

export const setQueueInfo = (payload) => {
  return dispatch => {
    dispatch({ type: SET_QUEUE_INFO, payload });
  };
};

export const setIncomingCallFlag = (flag) => {
  return dispatch => {
    dispatch({ type: SET_INCOMING_CALL_FLAG, payload: flag });
  };
};

export const setIsInbound = (flag) => {
  return dispatch => {
    dispatch({ type: INBOUND_CALL_TYPE, payload: flag });
  };
};

export const setTransferFlag = (flag) => {
  return dispatch => {
    dispatch({ type: SET_TRANSFER_FLAG, payload: flag });
  };
};

export const setQuickConnect = (quickConnect) => {
  return dispatch => {
    dispatch({ type: SET_QUICK_CONNECT, payload: quickConnect });
  };
};
