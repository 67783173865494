import React from 'react';
import styles from '../styles/Loader.module.scss';

const divsRequired = {
  box: 9,
  lines: 5,
  pulse: 1,
};

const classNames = {
  box: styles.ballGridPulse,
  lines: styles.lineScale,
  pulse: styles.ballScale,
};

const Loader = ({ type, text, children }) => {
  return (
    <div className={styles.loader}>
      {(text ?? '') && <div className={styles.text}>{text}</div>}

      <div className={classNames[type]}>
        {new Array(divsRequired[type]).fill(0).map((_, index) => (
          <div key={index}></div>
        ))}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Loader;
