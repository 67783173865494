import { useSelector } from 'react-redux';
import AgentInfo from './AgentInfo';
import CustomerInfo  from './CustomerInfo';
import '../styles/SideBar.module.scss';

const SideBar = (props) => {
  const contactState = useSelector(state => state.contact);
  const customerState= useSelector(state=>state.customer);
  const { incomingCallFlag, queueInfo, transfer, quickConnect } = contactState;
  const { cardNumber, brandAttribute, ivrDropoutCode, lastTransaction,identificationStatus,verificationStatus,verificationQuestion } = customerState.custInfo;
  const transferType= (incomingCallFlag === true && transfer === true)?'Agent To Agent':'IVR To Agent';

  return (
    <div className={props.className}>
      <fieldset>
        <legend>Agent Info:</legend>
        <AgentInfo quickConnect={quickConnect} queueName={queueInfo.name} incomingCallFlag={incomingCallFlag} transfer={transfer} />
      </fieldset>
      {(incomingCallFlag === true && brandAttribute === 'AFS') ? <fieldset><legend>Customer Info:</legend><CustomerInfo cardNumber={cardNumber} brandAttribute='Argos' incomingCallFlag={incomingCallFlag} transferType={transferType} ivrDropoutCode={ivrDropoutCode}  lastTransaction={lastTransaction} identificationStatus={identificationStatus}  verificationStatus={verificationStatus} verificationQuestion={verificationQuestion}/></fieldset>:null}
    </div>
  );
};


export default SideBar;